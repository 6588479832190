import img from "./images/og.png"
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, remove, onValue, serverTimestamp } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DB_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);

const db = getDatabase();
const usersRef = ref(db, 'users')

let connected = false;
let interval;
let userId;

const bg = document.getElementById("bg")
const glow = document.getElementById("inner")
const button = document.getElementById("connect")

button.addEventListener('click', () => {
  if (connected) {
    removeUserConnection()
    connected = false
    // button.innerText = "Connect"
    button.classList.remove("connected")
    bg.classList.remove("connected")
  } else if (!connected) {
    addUserConnection()
    connected = true
    // button.innerText = "Disconnect"
    button.classList.add("connected")
    bg.classList.add("connected")
  }
})

function getUserCount() {
  onValue(usersRef, snapshot => {
    const count = Object.keys(snapshot.val()).length - 1 || 0; // Ignore placeholder (-1)
    updateUI(count);
  }, error => {
    console.error('Error getting user count:', error);
  });
}

function updateUI(count) {
  document.getElementById('dev').innerText = count;
  bg.style.width = `${100 + (count * 50)}%`
  bg.style.height = `${100 + (count * 50)}%`
  if (count > 1) {
    glow.classList.add('connected')
  } else {
    glow.classList.remove("connected")
  }
}

getUserCount();

document.addEventListener('DOMContentLoaded', () => {
  const params = new URLSearchParams(window.location.search)
  if (params.get('dev')) {
    document.getElementById('dev').style.display = "block"
  }
});

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    if (connected) {
      removeUserConnection()
      connected = false
      button.innerText = "Connect"
      button.classList.remove("connected")
      bg.classList.remove("connected")
    }
  }
});

function generateUniqueId() {
  return Math.random().toString(36).substr(2, 6);
}

function addUserConnection() {
  userId = generateUniqueId();
  const userRef = ref(db, 'users/' + userId);

  set(userRef, {
    timestamp: serverTimestamp(),
  })
    .then(() => {
        // console.log('User connected:', userId);
    })
    .catch(error => {
        console.error('Error adding user connection:', error);
    });

  // Heartbeat
  interval = setInterval(() => {
    set(userRef, {
      timestamp: serverTimestamp(),
    })
  }, 30000); // 30 seconds
}

function removeUserConnection() {
  if (userId) {
    const userRef = ref(db, 'users/' + userId);
    remove(userRef)
      .then(() => {
          // console.log('User disconnected:', userId);
      })
      .catch(error => {
          console.error('Error disconnecting user:', error);
      });

    clearInterval(interval);
  } else {
    console.warn('User ID not found.');
  }
}